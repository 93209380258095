import React, { Suspense, useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { API_URL, FAVICON_URL } from "./utils/constant";
import { createKeycloakInstance, getKeycloakLogoutUrl } from "./config/keycloak";
import "./scss/style.scss";
import Loader from "./components/AppLoader";

const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));
const Login = React.lazy(() => import("./views/login/Login"));
const ForgotPassword = React.lazy(() => import("./views/forgotPassword/ForgotPassword"));
const ResetPassword = React.lazy(() => import("./views/resetPassword/ResetPassword"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

const extractQueryParam = (param) => {
  try {
    // Try extracting from `window.location.search`
    const urlParams = new URLSearchParams(window.location.search);
    const value = urlParams.get(param);
    if (value) return value.trim();

    // Try extracting from `window.location.href` (in case React strips params)
    const queryString = window.location.href.split("?")[1];
    if (queryString) {
      const params = new URLSearchParams(queryString);
      return params.get(param) ? params.get(param).trim() : null;
    }

    // Try extracting from `window.location.hash` (if using hash-based routing)
    const hashParams = new URLSearchParams(window.location.hash.split("?")[1]);
    return hashParams.get(param) ? hashParams.get(param).trim() : null;
  } catch (error) {
    return null;
  }
};

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [keycloak, setKeycloak] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const initializeApp = async () => {
      try {
        // Set favicon dynamically
        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
          link = document.createElement("link");
          link.rel = "icon";
          document.getElementsByTagName("head")[0].appendChild(link);
        }
        link.href = FAVICON_URL;

        // Extract parameters from URL
        const urlRealm = extractQueryParam("realm");
        const urlClientId = extractQueryParam("clientId");
        const urlCustomerName = extractQueryParam("customerName");

        // Retrieve from localStorage if not found in URL
        let realm = urlRealm || localStorage.getItem("realm");
        let clientId = urlClientId || localStorage.getItem("clientId");
        let customerName = urlCustomerName || localStorage.getItem("customerName");

        // Ensure no "null" string values are stored
        realm = realm !== "null" ? realm : null;
        clientId = clientId !== "null" ? clientId : null;
        customerName = customerName !== "null" ? customerName : null;

        // Store in localStorage if newly retrieved from URL
        if (urlRealm && urlClientId && urlCustomerName) {
          localStorage.setItem("realm", urlRealm);
          localStorage.setItem("clientId", urlClientId);
          localStorage.setItem("customerName", urlCustomerName);
        }


        // Stop initialization if required values are missing
        if (!realm || !clientId || !customerName) {
          setIsLoading(false);
          return;
        }

        // Initialize Keycloak
        const keycloakInstance = createKeycloakInstance(realm, clientId, customerName);
        setKeycloak(keycloakInstance);

        const authenticated = await keycloakInstance.init({
          onLoad: "login-required",
          silentCheckSsoRedirectUri: window.location.origin,
          checkLoginIframe: false,
        });

        setIsAuthenticated(authenticated);

        if (authenticated) {
          const email = keycloakInstance.tokenParsed?.email;
          const family_name = keycloakInstance.tokenParsed?.family_name;
          const given_name = keycloakInstance.tokenParsed?.given_name;
          const name = keycloakInstance.tokenParsed?.name;
          const customerID = keycloakInstance.tokenParsed?.customerID;

          if (email) {
            try {
              const response = await fetch(`${API_URL}/login-keycloak`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${keycloakInstance.token}`,
                },
                body: JSON.stringify({ email, family_name, given_name, name, customerName, customerID }),
              });

              if (response.status === 404) {
                alert("⚠️ Client not found in Phishing Database");
                const logoutUrl = getKeycloakLogoutUrl();
                if (logoutUrl) {
                  window.location.href = logoutUrl;
                  return;
                }
              }

              const { data } = await response.json();
              if (data.authToken && data.user) {
                localStorage.setItem("authToken", data.authToken);
                localStorage.setItem("currentCustomer", data.user._id);
                window.keycloakInstance = keycloakInstance;

                if (window.location.pathname === "/login") {
                  window.location.href = "/dashboard";
                }
              } else {
              }
            } catch (error) {
            }
          }
        }
      } catch (error) {
      }

      setIsLoading(false);
    };

    initializeApp();
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <BrowserRouter>
      <Suspense fallback={<Loader />}>
        {keycloak ? (
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/404" element={<Page404 />} />
            <Route path="/500" element={<Page500 />} />
            <Route path="/" element={<Navigate to="/dashboard" replace />} />
            <Route path="*" element={<DefaultLayout />} />
          </Routes>
        ) : (
          <Routes>
            <Route path="/forgot/password" element={<ForgotPassword />} />
            <Route path="/reset/password/" element={<ResetPassword />} />
            <Route path="/reset/password/:resetCode" element={<ResetPassword />} />
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<Navigate to="/login" replace />} />
            <Route path="*" element={<DefaultLayout />} />
          </Routes>
        )}
      </Suspense>
    </BrowserRouter>
  );
};

export default App;
