import React from 'react';

import { CSpinner } from '@coreui/react-pro';

const Loader = ({hidden}) => {
	return (
		<div style={{height: '90vh'}}>
			<div style={{height: '100%'}} className='d-flex justify-content-center align-items-center'>
				<CSpinner color='primary' hidden={hidden} />
			</div>
		</div>
	);
};

export default Loader;
