import {
    FETCHED_NEWS_FEEDS,
    FETCHING_NEWS_FEEDS
} from '../actions/';

const initState = { loading: true, response: null, list: [] };

export default function (state = initState, action) {
    switch (action.type) {
        case FETCHING_NEWS_FEEDS:
            return { ...state, response: null, list: [], loading: true };
        case FETCHED_NEWS_FEEDS:
            return { ...state, response: action.payload, list: action.payload?.rss?.channel?.item || [], loading: false };
        default:
            return state;
    }
}
