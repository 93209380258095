// import {
//     CREATE_TOKEN,
//     CREATE_TOKEN_SUCCESS,
//     CREATE_TOKEN_ERROR,
//     GET_TOKEN,
//     GET_TOKEN_SUCCESS,
//     GET_TOKEN_ERROR,
//     UPDATE_TOKEN,
//     UPDATE_TOKEN_SUCCESS,
//     UPDATE_TOKEN_ERROR,
// } from '../actions';

// const initialState = {
//     loading: false,
//     tokens: [],
//     error: null,
// };

// const tokenReducer = (state = initialState, action) => {
//     switch (action.type) {
//         case CREATE_TOKEN:
//         case GET_TOKEN:
//         case UPDATE_TOKEN:
//             return { ...state, loading: true, error: null };

//         case CREATE_TOKEN_SUCCESS:
//             return { ...state, loading: false, tokens: [...state.tokens, action.payload] };

//         case GET_TOKEN_SUCCESS:
//             return { ...state, loading: false, tokens: action.payload };

//         case UPDATE_TOKEN_SUCCESS:
//             return {
//                 ...state,
//                 loading: false,
//                 tokens: state.tokens.map((token) =>
//                     token.id === action.payload.id ? action.payload : token
//                 ),
//             };

//         case CREATE_TOKEN_ERROR:
//         case GET_TOKEN_ERROR:
//         case UPDATE_TOKEN_ERROR:
//             return { ...state, loading: false, error: action.payload };

//         default:
//             return state;
//     }
// };

// export default tokenReducer;


import {
    CREATE_TOKEN,
    CREATE_TOKEN_SUCCESS,
    CREATE_TOKEN_ERROR,
    GET_TOKEN,
    GET_TOKEN_SUCCESS,
    GET_TOKEN_ERROR,
    UPDATE_TOKEN,
    UPDATE_TOKEN_SUCCESS,
    UPDATE_TOKEN_ERROR,
} from '../actions';

const initialState = {
    loading: false,
    data: [], // Renamed from 'tokens' for consistency
    error: null,
};

const tokenReducer = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_TOKEN:
        case GET_TOKEN:
        case UPDATE_TOKEN:
            return {
                ...state,
                loading: true,
                error: null, // Reset error on new request
            };

        case CREATE_TOKEN_SUCCESS:
            return {
                ...state,
                loading: false,
                data: [...state.data, action.payload], // Append new token to existing data
            };

        case GET_TOKEN_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload, // Replace entire data array with fetched tokens
            };

        case UPDATE_TOKEN_SUCCESS:
            return {
                ...state,
                loading: false,
                data: state.data.map((token) =>
                    token.id === action.payload.id ? { ...token, ...action.payload } : token
                ), // Update the specific token
            };

        case CREATE_TOKEN_ERROR:
        case GET_TOKEN_ERROR:
        case UPDATE_TOKEN_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload, // Store error details
            };

        default:
            return state; // Return current state for unrecognized action types
    }
};

export default tokenReducer;

