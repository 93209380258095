import { CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from "@coreui/react-pro";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { confirmDeleteBox } from "src/actions/confirmDeleteBox";

const ConfirmDeleteModal = () => {
    const dispatch = useDispatch();
    const confirmText = useSelector(state => state.confirmDeleteBox?.confirmText);
    const onConfirm = useSelector(state => state.confirmDeleteBox?.onConfirm);
    const onHardConfirm = useSelector(state => state.confirmDeleteBox?.onHardConfirm);
    const visible = useSelector(state => state.confirmDeleteBox?.visible);

    return (
        <>
            <CModal className="app-font" visible={visible} onClose={() => confirmDeleteBox.close(dispatch)} aria-labelledby='confirmModal'>
                <CModalHeader onClose={() => confirmDeleteBox.close(dispatch)}>
                    <CModalTitle id='confirmModal'>Are you sure?</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <p>{confirmText}</p>
                </CModalBody>
                <CModalFooter>
                    <CButton className='outline-btn' onClick={() => confirmDeleteBox.close(dispatch)}>
                        Cancel
                    </CButton>
                    <CButton className='primary-btn' onClick={onHardConfirm}>
                        Permanent Delete
                    </CButton>
                    <CButton className='primary-btn' onClick={onConfirm}>Temporary Delete</CButton>
                </CModalFooter>
            </CModal>
        </>
    );
};

export default ConfirmDeleteModal;