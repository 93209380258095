import { CLOSE_CONFIRM_BOX, SHOW_DELETE_CONFIRM_BOX } from '../actions';

const showdelete = async (options, dispatch) => {
    dispatch({
        type: SHOW_DELETE_CONFIRM_BOX,
        payload: {
            ...options, 
            onConfirm: () => {
                options.onConfirm && options.onConfirm();
                close(dispatch);
            },
            onHardConfirm: ()=>{
                options.onHardConfirm && options.onHardConfirm();
                close(dispatch);
            }
        },
    });
};

const close = async (dispatch) => {
    dispatch({
        type: CLOSE_CONFIRM_BOX
    });
};


export const confirmDeleteBox = {
    showdelete,
    close
}